import { AppRouter } from "./router";


function App() {
  return (
    <div className="App">
        <AppRouter />
    </div>
  );
}

export default App;