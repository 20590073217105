import React, { useEffect, useState } from 'react';
import { Row ,Card} from 'reactstrap';
import AppLayout from '../../layout/appLayout';
import {ReactComponent as ForkAndKnife} from './../../assets/img/category/forkAndKnife.svg';
import {ReactComponent as Coctail} from './../../assets/img/category/coctail.svg';
import {ReactComponent as Message} from './../../assets/img/category/message.svg';
import {ReactComponent as User} from './../../assets/img/category/user.svg';
import {ReactComponent as Microphone} from './../../assets/img/category/microphone.svg';
import './styles.scss';

const Screen = () => {

  const [plates,setPlates] = useState(
    
    [ 
      { name : "Salmão Grelhado", src : "./../../../img/plates/plate1.png", id : 1, },
      { name : "Salmão Cozido", src : "./../../../img/plates/plate2.png", id : 2, },
      { name : "Massa Vitela", src : "./../../../img/plates/plate3.png", id : 3, },
      { name : "Arroz Frango", src : "./../../../img/plates/plate4.png", id : 4, }, 
    ]);
  const [paddingValue, setPaddingValue] = useState(15);
  const [selectedPlateIndex, setSelectedPlateIndex] = useState(0);
  const [deg, setDeg] = useState(45);
  const [roulette,setRoulette] = useState([plates[0],{},{},{}]);  
  const [activePlate,setActivePlate] = useState(null);
  const [selectedPlate, setSelectedPlate] = useState(plates[0]);
  const [platesNames, setPlatesNames] = useState(selectedPlate?.name.split(" "));
  const [isAnimating,setIsAnimating] = useState(false);
  const first = document.getElementById("first");
  const second = document.getElementById("second");

  const selectPlate = (plate,index) => {

    setSelectedPlateIndex(index);

    if(plate !== selectedPlate){

      if(activePlate <= 2){
          setActivePlate(activePlate + 1);
      }
      else{
        setActivePlate(0);
      }
      
      setIsAnimating(true);
      setSelectedPlate(plate);
      setTimeout(() => {
        setIsAnimating(false);
      }, 1000);

    }

    else{
      return null;
    }

  }

  useEffect(() => {
    
      if(activePlate !== null){
        const newRoulette = [...roulette];
        newRoulette[activePlate] = selectedPlate;
        setRoulette(newRoulette);
        setDeg(deg => deg + 90);


      }

      if(selectedPlate){
        setTimeout(() => {
          setPlatesNames(selectedPlate?.name.split(" "));
        }, 1000);
        
      }
    
  },[activePlate, selectedPlate])

  return(

    <AppLayout paddingValue={paddingValue}>
      <Row className="m-0 p-0 w-100 h-100 d-flex align-items-center justify-content-center position-relative overflow-hidden">
        <div className='plates-name'>
          <Row className={isAnimating ? "m-0 p-0 firstOut" : "m-0 p-0 firstEntry"}>
            <span>{platesNames[0]}</span>
          </Row>
          <Row className={isAnimating ? 'm-0 p-0 secondOut' : 'm-0 p-0 secondEntry'}>
            <span>{platesNames[1]}</span>
          </Row>
        </div>
        <div id='plate-roulette' className='plate-roulette' style={{ transform: `rotate(${deg}deg)`,}}>
          <div className='image-container'>
          {
            roulette.map((plateRoulette,index) => (
              <img key={index} src={plateRoulette?.src} style={{ transform: `rotate(${deg}deg)`,}}/>
            ))
          }
          </div>
        </div>
        <Row className='m-0 p-0 w-100 d-flex align-items-end justify-content-center cards-container'>
          <Row className='m-0 p-0 px-4 d-flex align-items-center position-relative w-auto'>
            <div className='active-plate' style={{left : `calc((198px * ${selectedPlateIndex}) + 48px)`}}/> 
            {
              plates.map((plate,index) => (
                <Card key={index} className='m-0 mx-4 p-0 d-flex align-items-center justify-content-center cards' onClick={() => selectPlate(plate,index)}>
                  <img src={plate?.src} alt="" />
                  <span>{plate?.name}</span>
                </Card>
              ))
            }
          </Row>   
        </Row>
        <Row className="m-0 p-0 w-100 d-flex align-items-end justify-content-center category-container">
          <ForkAndKnife className="category-svg" style={{background: "rgba(165, 164, 164, 0.2)", borderRadius: "50%",boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.15)"}} />
          <Coctail className="category-svg" />
          <Message className="category-svg"/>
          <User className="category-svg"/>
          <Microphone className="category-svg"/>
        </Row>
      </Row>  
    </AppLayout>

  );


}
export default Screen;