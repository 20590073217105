import { BrowserRouter,Routes,Route, Navigate} from "react-router-dom";
import Home from "../views/screen";


export const AppRouter = () => {

  return(
    <BrowserRouter>
      <Routes>
          <Route index element={<Navigate to="/app"/>} />
          <Route exact path="/app">
            <Route index element={<Home />}/>
          </Route>
      </Routes>
    </BrowserRouter>
  );
}