import React  from "react";
import 'bootstrap/dist/css/bootstrap.min.css';


const AppLayout = ({children, paddingValue}) => {

  return (
    <div style={{position: "fixed", width: "100%", height: "100%", margin: "0", padding: `${paddingValue}px`, overflow: "hidden", transition: "1s"}} >
      <main className="w-100 h-100">
        {children}
      </main>
    </div>
  );

}

export default AppLayout